<template>
  <div class="container">
    <div class="topHeader">
      <div class="bannerTit">
        <span>竞拍规则</span>
      </div>
    </div>

    <div class="content">
      <div class="tit1">第一节 准入</div>
      <div class="tit2">【入驻条件】</div>
      <div class="tit3">申请入驻电池之家-拍卖平台的，须满足以下条件：</div>
      <p>
        （一）申请入驻主体需具有经本平台实名认证，且仅限企业或机构为主体，个人暂未开放；
      </p>
      <p>
        （二）行业资质证件齐全、真实有效，提供行业相关的营业执照、组织机构代码证的清晰照片等其相关证件。
      </p>

      <div class="tit1">第二节 退出</div>
      <div class="tit2">【主动退出】</div>
      <div class="tit3">
        资产处置机构有权根据自身的经营情况，申请退出电池之家-拍卖平台，但必须同时满足以下条件：
      </div>
      <p>（一）不存在尚未偿还本拍卖平台的任何款项；</p>
      <p>
        （二）资产处置相关交易完结（交易关闭和交易成功），且无进行中的投诉维权；
      </p>
      <p>（三）不存在预展中的标的，即将开始的拍卖等。</p>
      <div class="tit2">【清退】</div>
      <div class="tit3">
        资产处置机构如出现以下任一情形，电池之家-拍卖有权将其清退：
      </div>
      <p>（一）资质作假，包括入驻申请资料、标的资质证明等；</p>
      <p>
        （二）包含但不限于盗用他人账户、骗取他人财物、扰乱市场秩序、不正当谋利等严重违规行为；
      </p>
      <p>（三）资产处置机构出售假冒标的；</p>
      <p>
        （四）竞买人收到的标的与达成交易时资产处置机构对标的的描述不相符，情节特别严重的；
      </p>
      <p>
        （五）欠缴应当向平台支付的服务费，或处置机构保证金余额因任何原因不足的，且经本拍卖平台催缴后未在三十天内支付或补足的；
      </p>

      <div class="tit1">第三节 经营</div>
      <div class="tit2">【经营限制】</div>
      <div class="tit3">
        已入驻的资产处置机构如因出现资质变更等情况导致不再符合入驻标准的，平台可要求该机构重新提供符合要求的资质材料或进行清退，在资料变更期间，平台可采取限制发拍、搜索降权等临时性市场管控措施。
      </div>

      <div class="tit2">【发布管理】</div>
      <div class="tit3">
        资产处置机构应遵守《电池之家拍卖平台拍卖发布规则》对商品及信息的发布要求。并保证有权发布标的信息，有权进行标的变价出售。
      </div>

      <div class="tit2">【如实描述】</div>
      <div class="tit3">
        资产处置机构应保证标的的真实性和合法性，在包含但不限于公告、标题、详情等标的描述信息中，依据国家法律法规及相关监管政策之要求，对标的的项目介绍、项目存在的质量或权利瑕疵、参与竞价者资格要求、除竞价款项外的额外费用规定（如果有）、可能导致项目无法成交的法定情形等用户权利限制性规则等必须说明的信息进行真实、完整的描述。
      </div>
      <div class="tit2">【滥发推广】</div>
      <div class="tit3">
        未经许可，发布易导致交易风险的外部网站的商品或信息，如发布社交、导购、团购、促销、购物平台等外部网站的名称、LOGO、二维码、超链接、联系账号等信息。
      </div>
      <div class="tit2">【竞价管理】</div>
      <div class="tit3">
        资产处置机构应维护良好的交易秩序，不得以任何形式操纵或干扰交易。
      </div>
      <div class="tit2">【交易限制】</div>
      <p>（一）标的发布到竞价结束期间，不得通过其他的渠道处置该标的。</p>
      <p>
        （二）资产处置机构不应采取任何手段或措施，包括但不限于明示或暗示用户使用其他途径脱离平台进行交易。
      </p>
      <p>
        （三）竞买人在资产处置频道竞得标的后，资产处置机构须严格按照竞价结束时的成交价格售出标的，除标的的挂牌公告、竞买须知以及详情信息中已经明示的相关费用，不得要求竞买人支出约定外的任何其他成本。
      </p>

      <div class="tit1">第四节 市场管理</div>
      <div class="tit2">【资产处置机构保证金】</div>
      <div class="tit3">
        资产处置机构保证金具有经营保证金和服务费保证金双重用途，具体资费标准请参考平台资费标准：
      </div>
      <p>（一）资产处置机构应根据协议约定或规则规定缴存或补足保证金。</p>
      <p>
        （二）资产处置机构发生违规行为，电池之家-拍卖平台有权对经营保证金进行划扣赔付，以保障竞买人和本拍卖平台权益。
      </p>
      <p>
        （三）如资产处置机构发布的标的违反《平台拍卖规则》对商品及信息的发布要求的或者资产处置机构因账户等原因被临时监管，为保护竞买人和资产处置机构权益，电池之家拍卖平台有权提前中止竞价活动并释放竞价过程的保证金。
      </p>
      <p>
        （四）竞买人发生竞拍不买违规行为，锁定的保证金将赔付给资产处置机构。
      </p>

      <div class="tit2">【发票】</div>
      <div class="tit3">
        资产处置机构应当按照国家有关规定或者商业惯例向竞买人出具发票等有效交易凭证或者服务单据。双方沟通确认一致，以避免后续产生不必要的纠纷。
      </div>

      <div class="tit2">【争议处理】</div>
      <div class="tit3">
        竞买人和资产处置机构基于标的物和竞价产生的纠纷争议由双方自行协商或通过相应法律途径解决，电池之家拍卖平台不予受理。
      </div>

      <div class="tit1">第五节 违规处理</div>
      <div class="tit2">【发布违禁标的】</div>
      <div class="tit3">
        资产处置机构发布法律或法规规定的违禁标的的，依据本规则的相关规定执行，特殊标的符合国家法律法规的除外。
      </div>

      <div class="tit2">【成交不卖】</div>
      <div class="tit3">
        竞买人竞得标的后，资产处置机构拒绝以成交价（含约定的其他费用，如佣金、运费等）交付标的，妨害竞买人权益的，视为成交不卖，首次发生计违规一次，并视情况对机构进行限制发拍、取消机构已报名的营销活动，标的物搜索降权等临时性市场管控措施，一个自然年内再次出现违规平台有权进行清退。政策原因除外。
      </div>
      <div class="tit3">特殊说明：</div>
      <p>
        （一）资产处置是否为成交不卖，不以订单状态为准，以线下手续交割为准；
      </p>
      <p>
        （二）资产处置中，如处置机构在标的页面明确标注竞买人资质要求，而竞得标的者不符合该要求，处置机构不予发货或过户，不计为成交不卖；
      </p>
      <p>
        （三）资产处置中，如未特殊说明，标的需要竞买人进行线下交割，若说明包邮或其他运输形式的，处置机构未进行线上发货不计为成交不卖；
      </p>
      <p>
        （四）如标的涉及国家法律或政策原因无法成交，不计为成交不卖。如资产处置机构发生成交不卖情形的：机构需返还竞买人参与竞拍时锁定的保证金；
      </p>

      <div class="tit2">【竞拍不买】</div>

      <div class="tit3">
        如出现以下任一情形，都属于竞买人竞拍不买违规行为，但买卖双方另有约定或资产处置机构原因导致的除外：
      </div>
      <p>（一）竞买人竞得标的后未在处置机构要求的时间内支付尾款；</p>
      <p>
        （二）竞买人不符合该标的要求的竞买人资质。 竞买人发生竞拍不买情形的：
      </p>
      <p class="conS">1. 竞买人参与竞价时锁定的保证金赔付给处置机构；</p>
      <p class="conS">2. 退货产生的来回运费由竞买人承担；</p>
      <p class="conS">3. 如有相关的法律风险或损失，由竞买人承担。</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Project",
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  computed: {},
};
</script>
<!-- <style>
::v-deep .el-table__header-wrapper .el-table__header thead {
  box-shadow: 0 0 10px 10px rgb(250, 248, 248) !important;
}
</style> -->
<style lang="less" scoped>
.container {
  width: 100%;
  min-height: 70vh;
  padding-bottom: 50px;
  .topHeader {
    width: 100%;
    height: 200px; /* 让高度自适应，以保持图片的原始纵横比 */
    background: url("../../assets/images/bidProject/jpHeader.png") center/cover
      no-repeat; /* 替换为你的背景图路径 */
    .bannerTit {
      width: 1200px;
      height: 100%;
      margin: 0 auto;
      font-weight: 600;
      font-size: 28px;
      color: #ffffff;
      position: relative;
      span {
        position: absolute;
        bottom: 36px;
      }
    }
  }
  .content {
    width: 1200px;
    margin: 0 auto;
    line-height: 3em;
    padding-top: 40px;
    font-size: 14px;
    .tit1 {
      font-size: 20px;
      font-weight: 600;
      line-height: 60px;
    }
    .tit2 {
      font-size: 18px;
    }
    .tit3 {
      font-size: 16px;
    }
    p{
      text-indent: 2em;
    }
    .conS{
      text-indent: 4em;
    }
  }
}
</style>
